/* Privacy.module.css */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.privacy-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes up the full height */
    margin-top: 120px;
}

.content {
    flex: 1; /* Make the main content take up available space */
}
