/* ContactUs.css */

body, html {
    height: 100%;
    margin: 0;
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make sure the container takes up the full height */
    padding-top: 80px;
  }
  
  .content {
    flex: 1; /* Make the main content take up available space */
  }
  
  .contact-title {
    font-family: 'Arial', sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .contact-intro {
    margin-bottom: 20px;
  }
  
  .contact-details {
    margin-bottom: 40px; /* Add some space below the contact details */
  }
  
  .contact-box {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-heading {
    margin-bottom: 10px;
  }
  
  .contact-info {
    margin-bottom: 10px;
  }
  
  .form-box {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    margin-bottom: 20px;
  }
  
  .submit-button {
    margin-top: 20px;
  }
  