/* Services.css */

.services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 80px;
  }
  
  .services-title {
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px;
  }
  
  .services-intro {
    font-family: 'Arial', sans-serif;
    margin-bottom: 40px;
  }
  
  .section-box, .pricing-box {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section-title, .pricing-title {
    margin-bottom: 20px;
  }
  
  .service-box {
    margin-bottom: 20px;
  }
  
  .plan-box {
    padding: 20px;
    background-color: #f0f0f0 !important; /* Light gray background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .service-title {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
  }
  
  .service-description {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
  }
  
  .service-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .pricing-table th, .pricing-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .pricing-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .pricing-description {
    margin-bottom: 20px;
  }
  
  .plan-title {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
  }
  
  .plan-description {
    font-family: 'Arial', sans-serif;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Ensure equal height for all plan boxes */
  .plan-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .plan-item {
    flex: 1 1 calc(33.333% - 20px); /* Adjust the width */
    margin: 10px;
    display: flex;
  }
  
  .plan-box {
    flex: 1;
  }
  