.header {
  background-color: rgb(74, 72, 72) !important;
}

.logo {
  height: 120px;
  margin-right: 20px;
}

.signInButton {
  border-radius: 35px !important;
  background-color: #21b6ae !important;
  padding: 18px 36px !important;
  font-size: 18px !important;
}

  