body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: linear-gradient(to right, #282c34, #484e5a);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  font-family: 'Arial', sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.App-header-element {
  transition: transform 0.3s ease-in-out;
}

.App-header-element:hover {
  transform: scale(1.05);
}

.App-link {
  color: #2d96b3;
}

.container {
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;
}

h1 {
  font-weight: bold;
  color: #333;
  font-size: 3.75rem;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  margin: 20px auto;
  max-width: 90%;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-weight: bold;
  color: #21b6ae;
  font-size: 28px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

h2_white {
  font-weight: bold;
  color: #bcb9b9;
  font-size: 28px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

h2_normal {
  font-weight: normal;
  color: #bcb9b9;
  font-size: 26px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

h4 {
  font-weight: normal;
  color: #0a3634;
  font-size: 24px;
  text-align: center;
}

h4_black {
  font-weight: normal;
  color: #0e0e0e;
  font-size: 24px;
  text-align: center;
}

font_0 {
  font-weight: bold;
  color: #fff;
  font-size: 32px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

font_1 {
  font-weight: normal;
  color: #cac6c6;
  font-size: 18px;
  text-align: justify;
}

/* New styles for the updated layout */
.main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 50px;
}

.header-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container img {
  height: 80px; /* Adjust as needed */
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.section {
  margin-bottom: 40px;
}

.video-section-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start; /* Align items at the start */
  justify-content: space-between; /* Add space between text box and video */
  flex-wrap: wrap;
  gap: 20px; /* Add gap between text box and video */
}

.text-box {
  background-color: #3e5367; /* Dark blue color */
  padding: 20px;
  flex: 1;
  max-width: 45%;
  height: auto; 
  z-index: 1;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-inside-box {
  color: white;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 24px;
  line-height: 1.2;
}

.white-text {
  color: white;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  font-size: 40px;
}

.black-text {
  color: black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  font-size: 40px;
}

.video-section {
  flex: 1;
  max-width: 45%;
  z-index: 2;
}

.video-section video {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grid-section {
  background-color: #f0f0f0; /* Light gray background */
  padding: 40px 20px; /* Add padding for consistency */
  display: flex;
  justify-content: center;
  margin: 0 auto; /* Center the grid section */
  width: 100%;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.grid-item-container {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.grid-item {
  position: relative;
  width: 30%;
  height: 300px; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  margin: 10px 0; /* Adjust the margin as needed */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensure the content doesn't overflow */
}

.grid-item h6 {
  color: #115f5b;
  margin-bottom: 10px;
}

.grid-item p {
  color: #333;
}

.middle-section-wrapper {
  margin: 40px 0; /* Add equal space on top and bottom */
  padding: 40px; /* Add padding to make the section larger */
  background-color: #f9f9f9; /* Optional: Add a background color for better visibility */
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.middle-text-box {
  background-color: #3e5367; /* Dark blue color */
  padding: 20px;
  width: 45%; /* Adjust width as needed */
  border-radius: 20px;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.middle-text-inside-box {
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

.middle-text-title {
  color: #21b6ae; 
  font-family: 'Bebas Neue', sans-serif;
  font-size: 32px; /* Increase the font size */
}

.demo-section {
  width: 45%; /* Adjust width as needed */
  z-index: 2;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.demo-title {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.demo-placeholder {
  background-color: #e0e0e0; /* Light gray color */
  padding: 40px; /* Increase padding for a larger demo box */
  position: relative;
  z-index: 1;
  border-radius: 20px;
  height: auto; /* Adjust height for better visibility of slides */
}

.demo-text {
  color: #333;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  margin: 20px 0; /* Add margin to space out the content */
}

/* Styles for the nav arrows */
.nav-arrows {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nav-arrow {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
  color: #000; /* Make arrows visible */
}

/* Styles for the image section */
.image-section {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 0; /* Remove bottom margin */
  background-color: #f0f0f0; 
  overflow: hidden;
}

.new-image-box {
  position: relative;
  width: 100%;
  height: 100%; /* Make the box take the full height */
}

.full-width-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire box */
  display: block;
  margin: 0 auto;
}

.new-image-overlay {
  position: absolute;
  top: 50%; /* Center the overlay vertically */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  width: 80%; /* Adjust the width as needed */
  box-sizing: border-box;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}

.overlay h6 {
  margin: 0;
  font-size: 24px;
  /* color: #21b6ae; */
  color: #ffffff;
}

/* .overlay p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #ffffff;
} */

/* Add this CSS to your App.css or a similar stylesheet */

.grid-item {
  perspective: 1000px;
}

.flip-card {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-card.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flip-card-back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}
