/* S3Link.css */

#mainSection {
  text-align: left;   /* Default to left align content */
  width: 100%;       /* Full width to avoid any alignment issues */
  padding: 20px;     /* Add some padding for better layout */
}

.light-gray-box {
  background-color: rgba(240, 240, 240, 0.9); /* Light gray with slight transparency */
  padding: 20px; /* Padding inside the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 20px 0; /* Margin to separate from other elements */
}

/* General container styling for a box-like background */
.container {
  background-color: #f9f9f9;  /* Light grey background for the container */
  border: 1px solid #ddd;     /* Light grey border */
  padding: 20px;              /* Space inside the box */
  border-radius: 10px;        /* Rounded corners */
  max-width: 800px;           /* Maximum width of the container */
  margin: 20px auto;          /* Center align with margin */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: left;           /* Left-align text inside the container */
}

/* Styling for the User ID section */
#userIdSection {
  background-color: #e6f7ff;  /* Light blue background */
  border: 1px solid #b3daff;  /* Border matching the background */
  padding: 10px 20px;         /* Padding inside the box */
  border-radius: 5px;         /* Rounded corners */
  margin-bottom: 20px;        /* Space below the section */
  font-weight: bold;          /* Bold text for emphasis */
  text-align: left;           /* Left-align text inside the section */
}

/* Styling for the Paystubs sections */
#paystubsSection, #uploadSection {
  background-color: #ffffff;  /* White background for clarity */
  border: 1px solid #ddd;     /* Light grey border */
  padding: 15px;              /* Padding inside the box */
  border-radius: 5px;         /* Rounded corners */
  margin-bottom: 20px;        /* Space below the sections */
}

#paystubsSection h3, #uploadSection h3 {
  /* margin-top: 0;              Remove top margin for headings */
  text-align: left;           /* Left-align the headings */
}

/* Styling for the paystub list */
#paystubList {
  list-style-type: none;      /* Remove default list style */
  padding: 0;                 /* Remove padding */
}

#paystubList li {
  background-color: #f0f8ff;  /* Light background for list items */
  border: 1px solid #ccc;     /* Light grey border */
  padding: 10px;              /* Padding inside each item */
  margin-bottom: 5px;         /* Space between items */
  border-radius: 5px;         /* Rounded corners */
  text-align: left;           /* Left-align text inside the items */
}

#paystubList li a {
  text-decoration: none;      /* Remove underline from links */
  color: #0073e6;             /* Blue color for links */
  font-weight: bold;          /* Bold text for emphasis */
  text-align: left;           /* Left-align text inside the links */
}

#paystubList li a:hover {
  text-decoration: underline; /* Underline on hover */
}


/* New styling for "Documents for Application" */
.documents-header {
  background-color: #3b4859; /* Dark bluish-gray background */
  color: #fff; /* White text color for contrast */
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center-align the text */
}

/* File preview frame styling */
.file-preview-frame {
  border: 2px solid #ddd; /* Border around the preview area */
  border-radius: 8px; /* Rounded corners for a softer look */
  padding: 15px; /* Padding inside the frame */
  background-color: #fff; /* White background for contrast */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 20px; /* Spacing from top elements */
}

/* File preview styling */
.file-preview {
  width: 100%;
  height: 500px; /* Fixed height for the preview box */
  border: none; /* Remove default iframe border */
  border-radius: 8px; /* Match the frame’s rounded corners */
}

/* Container styling */
/* .container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  } */
  
  /* Header styling */
  h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Paragraph styling */
  p {
    font-size: 16px;
    color: #555;
  }
  
  /* Button styling */
/* General button style */
button {
  background-color: #007bff; /* Blue background color */
  color: white;              /* White text color */
  border: none;              /* Remove default border */
  border-radius: 5px;        /* Rounded corners */
  padding: 10px 20px;        /* Padding for the button */
  cursor: pointer;           /* Pointer cursor on hover */
  font-size: 16px;           /* Font size */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background and shadow */
}

.button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically (if needed) */
  margin: 20px 0;          /* Margin to separate from other elements */
}

/* Hover state */
button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Active state (when the button is clicked) */
button:active {
  background-color: #004080; /* Even darker blue when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slightly smaller shadow */
}

/* Specific style for the 'Connect to verify income' button */
#connectButton {
  background-color: #007bff; /* Blue color */
  color: white; /* White text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for the button */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background and shadow */
}

#connectButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

#connectButton:active {
  background-color: #004080; /* Even darker blue when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slightly smaller shadow */
}

/* Style for the 'Browse files' button */
#browseFilesButton {
  background-color: #28a745; /* Green background color */
}

#browseFilesButton:hover {
  background-color: #218838; /* Darker green on hover */
}

#browseFilesButton:active {
  background-color: #1e7e34; /* Even darker green on click */
}

  
  /* Link styling */
  a {
    color: #007bff;
    text-decoration: none;
    font-size: 16px;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* List styling */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  /* File preview styling */
  .file-preview {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  iframe {
    width: 100%;
    border: none;
    border-radius: 5px;
  }
  
  /* Section divider styling */
  .section-divider {
    margin: 20px 0;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }


.highlight-section {
  background-color: #fff3cd; /* Light yellow background for emphasis */
  border: 1px solid #ffeeba; /* Border matching the background */
  padding: 15px; /* Padding inside the box */
  border-radius: 5px; /* Rounded corners */
  margin: 20px 0; /* Margin to separate from other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 16px; /* Slightly larger font size */
  font-weight: bold; /* Bold text for emphasis */
}
